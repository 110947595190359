<template>
<b-container fluid>
  <b-row class="row text-white bg-kpmg-blue">
    <b-col align-self="center" cols="2" class="cols text-center" align-v="center">
      <h1 justify="center" align="center" class="kstyle title justify-content-md-center">Insights</h1>
    </b-col>
    <b-col cols="8" align-self="center" align-v="center" class="text-center justify-content-md-center">
        <b-input-group>
          <b-form-input v-on:keyup.enter="reload()" v-model="searchFilter" placeholder="Search......"></b-form-input>
          <b-input-group-append>
            <b-button size="sm" v-on:click="reload()">Search</b-button>
          </b-input-group-append>
        </b-input-group>
    </b-col>
    <b-col align-self="center" cols="2" class="cols text-center" align-v="center"></b-col>
  </b-row>
  <b-row class="mb-4" v-if="!loadingArticles">
    <b-col>
    <b-row class="text-white bg-kpmg-blue">
      <b-col cols="2"></b-col>
      <b-col align-self="center" cols="8" class="cols text-center" align-v="center">
        <b-form inline>
          <label class="mb-3" for="startdate">Start date: </label>
            <b-form-datepicker
              id="startdate"
              v-model="startDate"
              placeholder="Choose a date"
              v-on:hidden="reload()"
              today-button
              close-button
              locale="en-GB"
              :hide-header="true"
              :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
              class="ml-2 filter-button"
            ></b-form-datepicker>
            <label class="ml-2 mb-3" for="enddate">End date: </label>
            <b-form-datepicker
              id="enddate"
              v-model="endDate"
              v-on:hidden="reload()"
              today-button
              close-button
              :hide-header="true"
              locale="en-GB"
              :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
              class="ml-2 filter-button"
            ></b-form-datepicker>
          <b-button v-b-toggle.sidebar-tags class="filter-button">Types <b-badge v-if="tagsSelected.length!==0" variant="light">{{tagsSelected.length}}</b-badge></b-button>
          <b-button v-b-toggle.sidebar-topics class="filter-button">Topics <b-badge v-if="topicsSelected.length!==0" variant="light">{{topicsSelected.length}}</b-badge></b-button>
          <b-button v-b-toggle.sidebar-publishers class="filter-button">Sources <b-badge v-if="publishersSelected.length!==0" variant="light">{{publishersSelected.length}}</b-badge></b-button>
          <b-button v-b-toggle.sidebar-lists class="filter-button" v-if="permission.list">RIC Lists <b-badge v-if="listsSelected.length!==0" variant="light">{{listsSelected.length}}</b-badge></b-button>
          <b-btn class="filter-button" @click="resetFilters">Reset</b-btn>
        </b-form>
        <b-sidebar id="sidebar-lists" title="Types" v-on:hidden="reload()" backdrop backdrop-variant="transparent" shadow width="25%">
          <div class="px-3 py-2">
            <b-button-group>
              <b-button variant="outline-primary" class="text-center" v-on:click="setListsOptions('all')">all</b-button>
              <b-button variant="outline-primary" class="text-center" v-on:click="setListsOptions('none')">none</b-button>
            </b-button-group>
            <b-card class="m-3">
            <b-form-checkbox-group
                v-model="listsSelected"
                :options="listsOptions"
                button-variant="light"
                size="md"
                class="d-flex flex-column mx-auto text-left"
                switches
              ></b-form-checkbox-group>
            </b-card>
          </div>
        </b-sidebar>
        <b-sidebar id="sidebar-tags" title="Types" v-on:hidden="reload()" backdrop backdrop-variant="transparent" shadow width="25%">
          <div class="px-3 py-2">
            <!-- <b-input-group>
              <b-form-input type="search" v-model="filterCountries" placeholder="Type to Search" />
              <b-btn :disabled="!filterCountries" @click="filterCountries = ''">clear</b-btn>
            </b-input-group> -->
            <b-button-group>
              <b-button variant="outline-primary" class="text-center" v-on:click="setTagsOptions('all')">all</b-button>
              <b-button variant="outline-primary" class="text-center" v-on:click="setTagsOptions('none')">none</b-button>
            </b-button-group>
            <b-card class="m-3">
            <b-form-checkbox-group
                v-model="tagsSelected"
                :options="tagsOptions"
                button-variant="light"
                size="md"
                class="d-flex flex-column mx-auto text-left"
                switches
              ></b-form-checkbox-group>
            </b-card>
          </div>
        </b-sidebar>
        <b-sidebar id="sidebar-topics" title="Topics" v-on:hidden="reload()" backdrop backdrop-variant="transparent" shadow width="25%">
          <div class="px-3 py-2">
            <div>
              <b-button variant="outline-primary" class="mb-2 mr-2" v-on:click="allTopics">all</b-button>
              <b-button variant="outline-primary" class="mb-2 mr-2" v-on:click="noneTopics">clear</b-button>
              <b-button v-b-toggle.sidebar-topics variant="primary" class="mb-2 mr-2" v-on:click="reload">Apply</b-button>
            </div>
            <b-form-group horizontal>
              <b-input-group>
                <b-form-input v-model="searchTopic" placeholder="Type to search topic" />
                  <b-btn @click="searchTopic = ''">clear</b-btn>
              </b-input-group>
            </b-form-group>
            <b-card class="m-3">
              <div v-if="renderTopics">
                <div class="mb-4" v-for="group in topicsByGroupOptionsShow" :key="'topic-group-' + group.id">
                  <div v-if="group.regtopics.length > 0">
                    <div><h3>{{ group.name }}</h3></div>
                    <div v-for="topic in group.regtopics" :key="'topic-' + topic.id">
                      <b-form-checkbox v-model="topicsSelected" :value="topic.id" name="check-button" class="d-flex flex-column mx-auto text-left">
                        {{ topic.name }}
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </b-sidebar>
        <b-sidebar id="sidebar-publishers" title="Sources" v-on:hidden="reload()" backdrop backdrop-variant="transparent" shadow width="85%">
          <div class="px-3 py-2">
            <b-container>
              <b-row>
                <b-col>
                  <b-form-group horizontal>
                    <b-input-group>
                      <b-form-input v-model="searchSource" placeholder="Type to search source" />
                        <b-btn @click="searchSource = ''">clear</b-btn>
                    </b-input-group>
                  </b-form-group>
                  <b-button-group>
                    <b-button variant="outline-primary" class="text-center" v-on:click="setPublishersOptions('all')">all</b-button>
                    <b-button variant="outline-primary" class="text-center" v-on:click="setPublishersOptions('none')">none</b-button>
                  </b-button-group>
                  <b-card class="m-3">
                  <b-form-checkbox-group
                      v-model="publishersSelected"
                      :options="publishersOptionsShow"
                      button-variant="light"
                      size="md"
                      class="d-flex flex-column mx-auto text-left"
                      switches
                    ></b-form-checkbox-group>
                  </b-card>
                </b-col>
                <b-col v-if="$config.BUILD === 'full'">
                  <div>
                    <div><strong>Expert-led curation</strong></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('level1')">RIC Level 1</b-button></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('level2')">RIC Level 2</b-button></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('accounting')">Accounting</b-button></div>
                  </div>
                  <div>
                    <div><strong>Core</strong></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('ukcore')">UK Core</b-button></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('eucore')">EU Core</b-button></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('intcore')">International Core</b-button></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('uscore')">US Core</b-button></div>
                    <div><b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('dataprivacy')">Data privacy</b-button></div>
                  </div>
                </b-col>
                <b-col>
                  <div><strong>Countries</strong></div>
                  <div v-for="country in countries" :key="country">
                    <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="selectCountry(country)">{{ country }}</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </b-sidebar>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-container fluid>
          <b-row class="mt-4">
            <b-col cols="2"></b-col>
            <b-col cols="8">
              <b-row>
                <b-col>
                  <b-pagination
                  fixed
                    class="mt-0"
                    v-model="page"
                    :limit=10
                    :total-rows="totalRows"
                    :per-page="pageSize"
                  />
                </b-col>
                <b-col v-if="permission.report && $config.BUILD === 'full'">
                  <span v-if="!downloading">
                    <b-button variant="outline-primary" class="mb-2 mr-4" @click="download">generate report</b-button>
                    <b-button v-if="permission.articles" variant="outline-primary" class="mb-2" :to="{ name: 'WorkflowArticles' }">articles admin</b-button>
                  </span>
                  <span v-if="downloading" class="mx-2 mb-2">
                    <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
                    downloading news: {{newsDownloadedCount}}
                  </span>
                </b-col>
                <b-col fixed sm="2" align-content="center" class="mt-2 mr-4 text-right" justify="center" align="center">
                  {{totalRows}} Results
                </b-col>
              </b-row>
              <transition name="list" mode="out-in">
                <span v-if="loadingArticles" class="text-center transition-delay: 1.5s">Loading.....<i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
              </transition>
              <span v-if="!loadingArticles && articles.length > 0">
                <b-card class="mb-3" v-for="item in articles" :key="'publication-' + item.id">
                  <publication
                    :hideLink="false"
                    :propItem="item"
                    :key="item.id"
                  />
                  </b-card>
              </span>
              <b-pagination
                class="mt-4"
                v-model="page"
                :limit=10
                :total-rows="totalRows"
                :per-page="pageSize"
              />
            </b-col>
            <b-col cols="2"></b-col>
          </b-row>
        </b-container>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import _ from 'lodash'
import ac from '../libs/accesscontrol'
import moment from 'moment'
import Publication from '@/components/Article'
import sourcesGroups from '../libs/sourcesGroups.js'

export default {
  components: {
    Publication
  },
  computed: {
    publishersOptionsShow: function () {
      let result = this.publishersOptions
      if (this.searchSource.length > 0) {
        result = _.filter(result, x => {
          return x.text.toUpperCase().includes(this.searchSource.toUpperCase())
        })
      }
      return result
    },
    results: function () {
      const searchResults = this.articles.filter(articles => {
        return articles.title.toUpperCase().match(this.searchFilter.toUpperCase())
      })
      return searchResults
    },
    topicsByGroupOptionsShow: function () {
      const groups = this.topicsByGroup
      let result = []
      if (this.searchTopic.length > 1) {
        groups.forEach(group => {
          const row = {
            name: group.name,
            id: group.id,
            regtopics: []
          }
          row.regtopics = _.filter(group.regtopics, x => {
            return x.name.toUpperCase().includes(this.searchTopic.toUpperCase())
          })
          result.push(row)
        })
      } else {
        result = groups
      }
      return result
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      articles: [],
      downloading: false,
      endDate: moment().format('YYYY-MM-DD'),
      firstRun: true,
      latest: 0,
      lists: [],
      listsOptions: [],
      listsSelected: [],
      loadingArticles: true,
      newsDownloadedCount: 0,
      permission: {},
      publishers: [],
      publishersAll: [],
      publishersSelected: [],
      publishersOptions: [],
      pageSize: 20,
      page: 1,
      renderTopics: true,
      searchFilter: "",
      searchSource: '',
      searchTopic: '',
      startDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
      sources: [],
      tags: [],
      tagsOptions: [],
      tagsSelected: [],
      tagsSelectedFlat: [],
      topics: [],
      topicsByGroup: [],
      topicsOptions: [],
      topicsSelected: [],
      topicsSelectedFlat: [],
      totalRows: 0,
      advFilter: false
    }
  },
  created: async function () {
    document.title = "KPMG Regulatory Horizon: Insights"
    this.$store.commit('setBrand', 'Regulatory Horizon')
    this.permission.articles = ac.can(this.user.acgroups).createAny('article').granted
    this.permission.list = ac.can(this.user.acgroups).createAny('article').granted
    this.permission.report = ac.can(this.user.acgroups).createAny('article').granted
    // get list of tags to use as filter
    const favTopics = window.localStorage.getItem('frmCloudFavTopics')
    if (favTopics) {
      this.favTopics = JSON.parse(favTopics)
    }
    const response = await this.$Amplify.API.get('cosmos', '/articles/staticdata')
    this.lists = response.lists
    this.listsOptions = _.map(this.lists, x => { return { text: x.name, value: x.id } })
    this.sources = response.sources
    this.publishers = response.sources.map(x => x.publisher)
    this.publishers.forEach(element => this.publishersOptions.push({ text: element, value: element }))
    this.topics = response.topics
    this.topicsByGroup = response.topicsByGroup
    this.countries = _.uniq(this.sources.map(source => source.countryName)).sort()
    this.tags = response.tags
    this.tagsOptions = _.map(this.tags, x => { return { text: x.name, value: x.id } })
    if (this.$route.query.topic) {
      this.topicsSelected = this.$route.query.topic.toString().split(",").map(Number)
      let topicsSelected = []
      let topicsOptions = this.topicsOptions
      _.forEach(this.topicsSelected, function (tag) {
        topicsSelected.push(_.find(topicsOptions, { value: tag }).text)
      })
      this.setTagsOptions('all')
      let tagsOptions = this.tagsOptions
      let tagsSelected = []
      _.forEach(this.tagsSelected, function (tag) {
        tagsSelected.push(_.find(tagsOptions, { value: tag }).text)
      })
      this.tagsSelectedFlat = tagsSelected
      this.topicsSelectedFlat = topicsSelected
      this.advFilter = true
      this.setPublishersOptions('all')
    }
    this.load()
  },
  methods: {
    allTopics: function () {
      this.topicsSelected = _.map(this.topics, x => { return x.id })
    },
    custom: function (selection) {
      const groups = sourcesGroups
      let newSelection = _.uniq(_.concat(this.publishersSelected, groups[selection]))
      this.publishersSelected = newSelection
    },
    download: async function () {
      this.$logger.debug('loading started')
      this.downloading = true
      try {
        const params = {
          body: {
            filterByTopics: this.filterByTopics,
            filterByTags: this.filterByTags,
            page: 1,
            pagesize: 100,
            lists: this.listsSelected,
            publishers: this.publishersSelected,
            searchFilter: this.searchFilter,
            tags: this.tagsSelected,
            topics: this.topicsSelected,
            startDate: this.startDate,
            endDate: moment(this.endDate).add(1, 'days').format('YYYY-MM-DD')
          }
        }
        let news = []
        let response = []
        do {
          response = await this.$Amplify.API.post('cosmos', `/articles/full`, params)
          news = _.concat(news, response.results)
          params.body.page++
          this.newsDownloadedCount = news.length
          await this.$nextTick()
        } while (response.results.length === params.body.pagesize)
        news.forEach(x => {
          let events = x.events
          let tags = x.tags
          let regtopics = x.regtopics
          x.publications.forEach(y => {
            events = events.concat(y.events)
            tags = tags.concat(y.tags)
            regtopics = regtopics.concat(y.regtopics)
          })
          x.eventsCombined = _.uniqBy(events, 'id')
          x.tagsCombined = _.uniqBy(tags, 'id')
          x.regtopicsCombined = _.uniqBy(regtopics, 'id')
        })
        this.$store.commit('setArticles', news)
        this.$router.push({ name: 'ArticlesReport' })
      } catch (e) {
        this.$logger.warn('loading error' + e)
      }
      this.downloading = false
    },
    load: async function () {
      this.$logger.debug('loading started')
      this.loadingArticles = true
      try {
        let apiName = 'cosmos'
        let path = `/articles`
        let params = {
          body: {
            filterByTopics: this.filterByTopics,
            filterByTags: this.filterByTags,
            lists: this.listsSelected,
            page: this.page,
            pagesize: this.pageSize,
            publishers: this.publishersSelected,
            searchFilter: this.searchFilter,
            tags: this.tagsSelected,
            topics: this.topicsSelected,
            startDate: this.startDate,
            endDate: moment(this.endDate).add(1, 'days').format('YYYY-MM-DD')
          }
        }
        let response = await this.$Amplify.API.post(apiName, path, params)
        this.articles = response.results
        this.totalRows = response.total
        this.firstRun = false
        this.loadingArticles = false
      } catch (e) {
        this.$logger.warn('loading error' + e)
      }
    },
    filterTags: function () {
      this.tagsSelectedFlat = []
      let tagsOptions = this.tagsOptions
      let tagsSelected = []
      _.forEach(this.tagsSelected, function (tag) {
        tagsSelected.push(_.find(tagsOptions, { value: tag }).text)
      })
      this.tagsSelectedFlat = tagsSelected
      this.reload()
    },
    filterTopics: function () {
      this.topicsSelectedFlat = []
      let topicsOptions = this.topicsOptions
      let topicsSelected = []
      _.forEach(this.topicsSelected, function (tag) {
        topicsSelected.push(_.find(topicsOptions, { value: tag }).text)
      })
      this.topicsSelectedFlat = topicsSelected
      this.reload()
    },
    noneTopics: function () {
      this.topicsSelected = []
    },
    reload: function () {
      this.loadingArticles = true
      this.page = 1
      this.totalRows = 0
      this.collapseVisible = false
      this.load()
    },
    resetFilters: function () {
      this.loadingArticles = true
      this.page = 1
      this.totalRows = 0
      this.collapseVisible = false
      this.listsSelected = []
      this.searchFilter = ""
      this.tagsSelected = []
      this.tagsSelectedFlat = []
      this.topicsSelected = []
      this.topicsSelectedFlat = []
      this.publishersSelected = []
      this.endDate = moment().add(1, 'days').format('YYYY-MM-DD')
      this.startDate = moment().subtract(1, 'year').format('YYYY-MM-DD')
      this.load()
    },
    selectCountry: function (country) {
      const publishers = []
      _.each(this.sources, source => {
        if (source.countryName === country) {
          publishers.push(source.publisher)
        }
      })
      this.publishersSelected = _.uniq(_.concat(this.publishersSelected, _.uniq(publishers)))
    },
    setListsOptions: function (button) {
      if (button === "all") {
        this.listsSelected = _.map(this.lists, x => { return x.id })
      } else if (button === "none") {
        this.listsSelected = []
      }
    },
    setTagsOptions: function (button) {
      if (button === "all") {
        this.tagsSelected = _.map(this.tags, x => { return x.id })
      } else if (button === "none") {
        this.tagsSelected = []
      }
    },
    setTopicsOptions: function (button) {
      if (button === "favs") {
        this.topicsSelected = this.favTopics
      } else if (button === "all") {
        this.topicsSelected = _.map(this.topics, x => { return x.id })
      } else if (button === "none") {
        this.topicsSelected = []
      }
    },
    setPublishersOptions: function (button) {
      if (button === "all") {
        this.publishersSelected = this.publishers
      } else if (button === "none") {
        this.publishersSelected = []
      }
    }
  },
  watch: {
    page: 'load'
  }
}
</script>
<style>
.form-inline label{
  justify-content: left;
}
.publishers {
  max-height: 500px;
  overflow: auto;
  border-radius: 5px 5px 5px 5px;
}
.tags {
  max-height: 500px;
  overflow: auto;
  border-radius: 5px 5px 5px 5px;
}
.topics {
  max-height: 500px;
  overflow: auto;
  border-radius: 5px 5px 5px 5px;
}
.publishersSelection {
  max-height: 500px;
  width: 800px;
  overflow: auto;
  border-radius: 5px 5px 5px 5px;
}
.tagsSelection {
  max-height: 500px;
  overflow: auto;
  border-radius: 5px 5px 5px 5px;
}
.topicsSelection {
  max-height: 500px;
  width: 800px;
  overflow: auto;
  border-radius: 5px 5px 5px 5px;
}
body {
  padding-top: 100px;
}
.search {
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
}
.filter-info {
 width: 98%;
}
h1 {
  margin: 15px;
}
.b-sidebar {
  width: 40%;
  min-width: 320px;
}
.filter {
  max-width: 253px;
}
.filter-button {
  margin-left: 10px;
  margin-bottom: 15px;
}
.search {
  margin: 15px;
}
</style>
